import Loadable from '../../components/Loadable';
import { lazy } from 'react';
import { SavingsProvider } from '../../contexts/SavingsContext';
import { MerchantProvider } from '../../contexts/MerchantContext';
import { SupportProvider } from '../../contexts/SupportContext';
import { AdvancedProvider } from '../../contexts/AdvancedContext';
import { CredioProvider } from '../../contexts/CredioContext';
const AnalyticInventory = Loadable(lazy(() => import('../inventory')));
const AnalyticMerchant = Loadable(lazy(() => import('../merchant')));
const AnalyticCredio = Loadable(lazy(() => import('../credio')));
const AnvancedView = Loadable(lazy(() => import('../advanced')));

const AddReader = Loadable(lazy(() => import('../../views/material-kit/forms/addNewReader')));

const AppTable = Loadable(lazy(() => import('./tables/AppTable')));
const AppForm = Loadable(lazy(() => import('./forms/AppForm')));
const AppButton = Loadable(lazy(() => import('./buttons/AppButton')));
const AppIcon = Loadable(lazy(() => import('./icons/AppIcon')));
const AppProgress = Loadable(lazy(() => import('./AppProgress')));
const AppMenu = Loadable(lazy(() => import('./menu/AppMenu'))); //'../Savings'
const SavingsAnalytics = Loadable(lazy(() => import('../Savings'))); //''
const SupportAnalytics = Loadable(lazy(() => import('../Support'))); //''
const SavingsTableAnalytics = Loadable(lazy(() => import('./tables/savingsTable'))); //''
const MerchantTableAnalytics = Loadable(lazy(() => import('./tables/merchantTable'))); //''
const CredioTableAnalytics = Loadable(lazy(() => import('./tables/credioTable'))); //''
const CredioTableTransactionAnalytics = Loadable(lazy(() => import('./tables/credioTransactionTable'))); //''
const CredioTableReadersAnalytics = Loadable(lazy(() => import('./tables/readersTable'))); //''
const SavingsTablePumpsAnalytics = Loadable(lazy(() => import('./tables/pumpTable'))); //''
const VasSavingsTableTransactionAnalytics = Loadable(lazy(() => import('./tables/vasSavingsTransactionTable'))); //''
const SavingsTableTransactionAnalytics = Loadable(lazy(() => import('./tables/savingsTransactionTable'))); //''
const SellTableAnalytics = Loadable(lazy(() => import('./tables/sellsTable'))); //''
const CredioUserAnalytics = Loadable(lazy(() => import('../../views/credio/user'))); //''
const SavingUserAnalytics = Loadable(lazy(() => import('../../views/savings/user'))); //''
const SavingTransTypeAnalytics = Loadable(lazy(() => import('../../views/material-kit/tables/savingsTransTransactionTable'))); //''
const AppCheckbox = Loadable(lazy(() => import('./checkbox/AppCheckbox')));
const AppSwitch = Loadable(lazy(() => import('./switch/AppSwitch')));
const AppRadio = Loadable(lazy(() => import('./radio/AppRadio')));
const AppSlider = Loadable(lazy(() => import('./slider/AppSlider')));
const AppDialog = Loadable(lazy(() => import('./dialog/AppDialog')));
const AppSnackbar = Loadable(lazy(() => import('./snackbar/AppSnackbar')));
const AppAutoComplete = Loadable(lazy(() => import('./auto-complete/AppAutoComplete')));
const AppExpansionPanel = Loadable(lazy(() => import('./expansion-panel/AppExpansionPanel')));
const AllUserTable = Loadable(lazy(() => import('./tables/allUserTable')));
const materialRoutes = [
  {
    path: '/new/users',
    element: <AppTable />,
  },
  {
    path: '/all/users',
    element: <AllUserTable />,
  },
  {
    path: '/material/form',
    element: <AppForm />,
  },
  {
    path: '/analytics/inventory',
    element: <AnalyticInventory />,
  },
  // {
  //   path: '/analytics/merchant/reader/exchange',
  //   element: <ExchangeReader />,
  // },
  {
    path: '/analytics/merchant/reader/add',
    element: (
      <MerchantProvider>
        <AddReader />
      </MerchantProvider>
    ),
  },
  {
    path: '/analytics/credio',
    element: (
      <CredioProvider>
        <AnalyticCredio />
      </CredioProvider>
    ),
  },
  {
    path: '/analytics/credio/table/:type/:phoneNumber',

    element: (
      <CredioProvider>
        <CredioTableAnalytics />
      </CredioProvider>
    ),
  },
  {
    path: '/analytics/credio/transaction/:type',

    element: (
      <CredioProvider>
        <CredioTableTransactionAnalytics />
      </CredioProvider>
    ),
  },

  {
    path: '/analytics/credio/readers',

    element: (
      <CredioProvider>
        <CredioTableReadersAnalytics />
      </CredioProvider>
    ),
  },
  {
    path: '/analytics/credio/vas-savings/:type',

    element: (
      <CredioProvider>
        <VasSavingsTableTransactionAnalytics />
      </CredioProvider>
    ),
  },
  {
    path: '/analytics/savings/:user/:phoneNumber',

    element: (
      <SavingsProvider>
        <SavingsTableTransactionAnalytics />
      </SavingsProvider>
    ),
  },


  {
    path: '/analytics/savings/pumps/:type',

    element: (
      <SavingsProvider>
        <SavingsTablePumpsAnalytics />
      </SavingsProvider>
    ),
  },

  {
    path: '/analytics/credio/user/:phoneNumber',

    element: (
      <CredioProvider>
        <CredioUserAnalytics />
      </CredioProvider>
    ),
  },
  {
    path: '/analytics/savings/agent/:type/:phoneNumber',

    element: (
      <SavingsProvider>
        <SavingTransTypeAnalytics />
      </SavingsProvider>
    ),
  },
  {
    path: '/analytics/savings/user/details/:phoneNumber',

    element: (
      <SavingsProvider>
        <SavingUserAnalytics />
      </SavingsProvider>
    ),
  },
  {
    path: '/analytics/credio/table/:type',

    element: (
      <CredioProvider>
        <CredioTableAnalytics />
      </CredioProvider>
    ),
  },
  {
    path: '/analytics/sells/table/:type',

    element: (
      <SupportProvider>
        <SellTableAnalytics />
      </SupportProvider>
    ),
  },

  {
    path: '/tools/advance',

    element: (
      <AdvancedProvider>
        <AnvancedView />
      </AdvancedProvider>
    ),
  },
  {
    path: '/analytics/savings',

    element: (
      <SavingsProvider>
        <SavingsAnalytics />
      </SavingsProvider>
    ),
  },
  {
    path: '/analytics/sells',

    element: (
      <SupportProvider>
        <SupportAnalytics />
      </SupportProvider>
    ),
  },
  {
    path: '/analytics/savings/table/:type/:phoneNumber',

    element: (
      <SavingsProvider>
        <SavingsTableAnalytics />
      </SavingsProvider>
    ),
  },
  {
    path: '/analytics/savings/table/:type',

    element: (
      <SavingsProvider>
        <SavingsTableAnalytics />
      </SavingsProvider>
    ),
  },
  {
    path: '/analytics/merchant/table/:type',

    element: (
      <CredioProvider>
        <MerchantTableAnalytics />
      </CredioProvider>
    ),
  },
  {
    path: '/material/buttons',
    element: <AppButton />,
  },
  {
    path: '/material/icons',
    element: <AppIcon />,
  },
  {
    path: '/material/progress',
    element: <AppProgress />,
  },
  {
    path: '/material/menu',
    element: <AppMenu />,
  },
  {
    path: '/material/checkbox',
    element: <AppCheckbox />,
  },
  {
    path: '/material/switch',
    element: <AppSwitch />,
  },
  {
    path: '/material/radio',
    element: <AppRadio />,
  },
  {
    path: '/material/slider',
    element: <AppSlider />,
  },
  {
    path: '/material/autocomplete',
    element: <AppAutoComplete />,
  },
  {
    path: '/material/expansion-panel',
    element: <AppExpansionPanel />,
  },
  {
    path: '/material/dialog',
    element: <AppDialog />,
  },
  {
    path: '/material/snackbar',
    element: <AppSnackbar />,
  },
];

export default materialRoutes;
